import React from 'react'
import { ThemeUICSSObject } from 'theme-ui'
import HeadingV2, { HeadingProps } from '../heading'

interface PageTitleProps {
  title: string
  level?: HeadingProps['level']
}

export const PageTitle = ({ title, level = '1' }: PageTitleProps) => {
  const textAlign: ThemeUICSSObject['textAlign'] = level === '1' ? ['center', 'left'] : 'left'
  const fontWeight: ThemeUICSSObject['fontWeight'] = level === '1' ? 400 : [600, 400]
  return (
    <HeadingV2
      sx={{
        mb: 28,
        textTransform: 'uppercase',
        fontWeight,
        textAlign,
      }}
    >
      {title}
    </HeadingV2>
  )
}
