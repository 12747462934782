import React from 'react'
import ReactMarkdown from 'react-markdown'
import { Box } from 'theme-ui'
import Table from '~/components/table'
import HeadingV2 from '~/componentsV2/heading'
import theme from '~/gatsby-plugin-theme-ui'

import { ContactSectionsType } from '../types'
import { mountContactCols, mountTimeCols } from '../utils'

export const ContactSections = ({ sections }: { sections: ContactSectionsType[] }) => {
  if (!sections) return null

  return (
    <>
      {sections.map(section => {
        const openHoursNew = mountTimeCols(section?.openHours)
        const contactNew = mountContactCols(section?.contact)
        return (
          <Box
            key={`contact-section-${section.id}`}
            sx={{ mb: [theme.space.l, null, theme.space.l2] }}
          >
            <HeadingV2
              level="2"
              sx={{
                textAlign: ['left', null, 'left'],
                fontWeight: ['bold', 'normal'],
                fontSize: ['20px !important', '26px !important'],
              }}
            >
              <ReactMarkdown
                source={section.name}
                disallowedTypes={['paragraph', 'image', 'link', 'strong', 'emphasis']}
                unwrapDisallowed
              />
            </HeadingV2>
            <Table data={contactNew} tableSxProps={{ mb: theme.space.md2 }} />
            <Table data={openHoursNew} tableSxProps={{ mb: theme.space.md2 }} />
          </Box>
        )
      })}
    </>
  )
}
