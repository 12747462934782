import { OpenHoursType, ContactType } from './types'

export const mountTimeCols = (openHours?: OpenHoursType[]) => {
  if (!openHours) return []
  const openHoursNew = openHours.map(item => ({
    firstCol: item.day,
    secondCol: item.time,
  }))

  return openHoursNew
}

export const mountContactCols = (contact?: ContactType[]) => {
  if (!contact) return []
  const contactNew = contact.map(item => ({
    firstCol: item.method,
    secondCol: item.value,
  }))
  return contactNew
}
